exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activacion-jsx": () => import("./../../../src/pages/activacion.jsx" /* webpackChunkName: "component---src-pages-activacion-jsx" */),
  "component---src-pages-cambio-contrasena-jsx": () => import("./../../../src/pages/cambio-contrasena.jsx" /* webpackChunkName: "component---src-pages-cambio-contrasena-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-incontinencia-jsx": () => import("./../../../src/pages/incontinencia.jsx" /* webpackChunkName: "component---src-pages-incontinencia-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mi-cuenta-datos-personales-jsx": () => import("./../../../src/pages/mi-cuenta/datos-personales.jsx" /* webpackChunkName: "component---src-pages-mi-cuenta-datos-personales-jsx" */),
  "component---src-pages-mi-cuenta-jsx": () => import("./../../../src/pages/mi-cuenta.jsx" /* webpackChunkName: "component---src-pages-mi-cuenta-jsx" */),
  "component---src-pages-mi-cuenta-mis-pedidos-jsx": () => import("./../../../src/pages/mi-cuenta/mis-pedidos.jsx" /* webpackChunkName: "component---src-pages-mi-cuenta-mis-pedidos-jsx" */),
  "component---src-pages-mi-cuenta-mis-puntos-jsx": () => import("./../../../src/pages/mi-cuenta/mis-puntos.jsx" /* webpackChunkName: "component---src-pages-mi-cuenta-mis-puntos-jsx" */),
  "component---src-pages-mi-cuenta-mis-redenciones-jsx": () => import("./../../../src/pages/mi-cuenta/mis-redenciones.jsx" /* webpackChunkName: "component---src-pages-mi-cuenta-mis-redenciones-jsx" */),
  "component---src-pages-nuestra-marca-jsx": () => import("./../../../src/pages/nuestra-marca.jsx" /* webpackChunkName: "component---src-pages-nuestra-marca-jsx" */),
  "component---src-pages-resultados-jsx": () => import("./../../../src/pages/resultados.jsx" /* webpackChunkName: "component---src-pages-resultados-jsx" */),
  "component---src-templates-free-sample-product-template-jsx": () => import("./../../../src/templates/free-sample-product-template.jsx" /* webpackChunkName: "component---src-templates-free-sample-product-template-jsx" */),
  "component---src-templates-home-section-confirmation-sample-jsx": () => import("./../../../src/templates/homeSectionConfirmationSample.jsx" /* webpackChunkName: "component---src-templates-home-section-confirmation-sample-jsx" */),
  "component---src-templates-home-section-free-sample-jsx": () => import("./../../../src/templates/homeSectionFreeSample.jsx" /* webpackChunkName: "component---src-templates-home-section-free-sample-jsx" */),
  "component---src-templates-home-segundo-nivel-jsx": () => import("./../../../src/templates/homeSegundoNivel.jsx" /* webpackChunkName: "component---src-templates-home-segundo-nivel-jsx" */),
  "component---src-templates-terms-jsx": () => import("./../../../src/templates/terms.jsx" /* webpackChunkName: "component---src-templates-terms-jsx" */)
}

